import React from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import parse from "html-react-parser"
import { v4 as uuidv4 } from 'uuid';

// We're using Gutenberg so we need the block styles
import "@wordpress/block-library/build-style/style.css"
import "@wordpress/block-library/build-style/theme.css"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Ads from "../components/ads"
import Adsense1 from "../components/adsense1"
import Adsense2 from "../components/adsense2"
import Adsense3 from "../components/adsense3"
import Adsense4 from "../components/adsense4"

const BlogPageTemplate = ({ data: { page }, location }) => {
  const featuredImage = {
    fluid: page?.featuredImage?.node?.localFile?.childImageSharp?.fluid,
    alt: page?.featuredImage?.node?.altText || ``,
  }

const seoDescription = page.excerpt ? page.excerpt.substr(3, page.excerpt.length - 8) : null

  return (
    <Layout currentPath={location.pathname} isPage={page.databaseId}>
      <SEO title={page.title} description={seoDescription}/>

      <article className="blog-post blog-page">
        <header>
          <h1 className="blog-page-title">{parse(page.title)}</h1>

          {/* if we have a featured image for this post let's display it */}
          {featuredImage?.fluid && (
            <Image
              fluid={featuredImage.fluid}
              alt={featuredImage.alt}
            />
          )}
        </header>

        {!!page.content && (
            page.content.split('[reklama]').map((fragment, index) => (
              <div key={uuidv4()}>
                {parse(fragment)}
                {(page.content.split('[reklama]').length > 1 && index+1 !== page.content.split('[reklama]').length) && (
                  (index === 0) && (
                    <Adsense1 currentPath={location.pathname} />
                  )
                )}
                {(page.content.split('[reklama]').length > 1 && index+1 !== page.content.split('[reklama]').length) && (
                  (index === 1) && (
                    <Adsense2 currentPath={location.pathname} />
                  )
                )}
                {(page.content.split('[reklama]').length > 1 && index+1 !== page.content.split('[reklama]').length) && (
                  (index === 2) && (
                    <Adsense3 currentPath={location.pathname} />
                  )
                )}
                {(page.content.split('[reklama]').length > 1 && index+1 !== page.content.split('[reklama]').length) && (
                  (index === 3) && (
                    <Adsense4 currentPath={location.pathname} />
                  )
                )}
              </div>
            ))
        )}

        {(page.databaseId === 65497 ) && (
          <div className="gcse-searchresults-only" data-personalizedAds="false"></div>
        )}

      </article>

      <Ads currentPath={location.pathname} />
      
    </Layout>
  )
}

export default BlogPageTemplate

export const pageQuery = graphql`
  query BlogPageById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting the current post by id
    page: wpPage(id: { eq: $id }) {
      id
      excerpt
      content
      title
      databaseId
      date(formatString: "D MMMM YYYY [r.]", locale: "pl")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 85) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
    }
  }
`
